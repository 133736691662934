import React from 'react';
import Highlight from 'react-highlight';

import Layout from '../components/Layout';

class Article extends React.Component {
  render() {
    console.log(this.props.pageContext.article.title);
    return (
      <Layout>
        <h1>{this.props.pageContext.article.title}</h1>
        <Highlight innerHTML={true}>
          {this.props.pageContext.article.body.childMarkdownRemark.html}
        </Highlight>
      </Layout>
    );
  }
}

export default Article;
